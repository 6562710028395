import React, { useState } from "react";
import BoxTypo from "../components/BoxTypo";
import { Box, Grid, Button } from "@mui/material";
import {
  StaticImage,
  GatsbyImage,
  getImage,
  DynamicImage,
} from "gatsby-plugin-image";
import { graphql, useStaticQuery } from "gatsby";
import "../style/base.css";
const Main = () => {
  return (
    <>
      <Box></Box>
    </>
  );
};
export default Main;
